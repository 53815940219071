
































































































































































































export default function (Component) {
  Component.options.__source = "src/components/d2-container-cascader/index.vue"
}
