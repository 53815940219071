<template>
  <el-collapse>
      <el-card shadow="hover" style="height: 120px;">
          <el-upload
                  class="import-demo"
                  ref="upload"
                  :headers="headers"
                  :action="url"
                  :data="importData"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :auto-upload="false"
                  :accept="templateType === 'cbImport' || this.templateType === 'khyjImport' || this.templateType === 'fypzImport' || this.templateType === 'xjdImport' || this.templateType === 'xmbxjImport' || this.templateType === 'xjdbjImport' || this.templateType === 'xmbxjbjImport' || this.templateType === 'ndysImport' || this.templateType === 'ydysmxImport' || this.templateType === 'pldrcxImport' || this.templateType === 'amountImport' || this.templateType === 'zzImport' || this.templateType === 'bmxmsyImport' || this.templateType === 'yscbbImport' || this.templateType === 'clylImport' || this.templateType === 'khdaImport' || this.templateType === 'gdzcpdImport' || this.templateType === 'xcxyhImport' || this.templateType === 'gysmxImport' || this.templateType === 'khmxImport' || this.templateType === 'xjcbImport' || this.templateType === 'xjjpImport' || this.templateType === 'tbbjImport' || this.templateType === 'tbbjjpImport' || this.templateType === 'ygdaImport' || this.templateType === 'sygzImport' || this.templateType === 'wbxlwhImport' || this.templateType === 'hbsfImport' ? '.xlsx' : '.csv'"
                  :before-upload="beforeAvatarUpload"
                  :limit="limitUpload"
                  :on-error="handleError"
                  :on-success="handleSuccess"
                  :on-exceed="handleExceed"
                  :on-change="handleChange"
                  :file-list="fileList">
              <el-button slot="trigger" size="mini" type="primary">选择文件</el-button>
              <el-button style="margin-left: 10px;" size="mini" type="success" @click="submitUpload" :loading="loading">开始导入</el-button>
              <el-button v-if="this.templateType === 'cbImport'"  size="mini" type="warning" @click="bjsyDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'khyjImport'"  size="mini" type="warning" @click="khyjDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'bjImport'"  size="mini" type="info" @click="bjDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'szImport'"  size="mini" type="info" @click="szDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'xjImport'"  size="mini" type="info" @click="xjDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'srmbImport'"  size="mini" type="info" @click="srmbDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'cbglImport'"  size="mini" type="info" @click="cbglDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'gzdxImport'"  size="mini" type="info" @click="gzdxDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'pldhcxImport'"  size="mini" type="info" @click="pldhcxDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'ncmbImport'"  size="mini" type="info" @click="ncmbDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'drgzImport'"  size="mini" type="info" @click="drgzDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'rgcbImport'"  size="mini" type="info" @click="rgcbDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'blackListImport'"  size="mini" type="info" @click="blackListDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'xhqdImport'"  size="mini" type="info" @click="xhqdDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'lcgjImport'"  size="mini" type="info" @click="lcgjDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'fypzImport'"  size="mini" type="info" @click="fypzDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'skfkplhxImport'"  size="mini" type="info" @click="skfkplhxDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'xjdImport' || this.templateType === 'xmbxjImport'"  size="mini" type="info" @click="xjdDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'xjdbjImport' || this.templateType === 'xmbxjbjImport'"  size="mini" type="info" @click="xjdbjDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'ndysImport'"  size="mini" type="info" @click="ndysDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'ydysmxImport'"  size="mini" type="info" @click="ydysmxDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'pldrcxImport'"  size="mini" type="info" @click="pldrcxDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'zzImport'"  size="mini" type="info" @click="zzDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'amountImport'"  size="mini" type="info" @click="amountDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'bmxmsyImport'"  size="mini" type="info" @click="bmxmsyDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'yscbbImport'"  size="mini" type="info" @click="yscbbDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'clylImport'"  size="mini" type="info" @click="clylDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'ydmxjgImport'"  size="mini" type="info" @click="ydmxjgDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'khdaImport'"  size="mini" type="info" @click="khdaDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'gdzcpdImport'"  size="mini" type="info" @click="gdzcpdDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'xcxyhImport'"  size="mini" type="info" @click="xcxyhDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'gysmxImport'"  size="mini" type="info" @click="gysmxDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'khmxImport'"  size="mini" type="info" @click="khmxDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'xjcbImport'"  size="mini" type="info" @click="xjcbDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'xjjpImport'"  size="mini" type="info" @click="xjjpDownload">下载询价成本表</el-button>
              <el-button v-else-if="this.templateType === 'tbbjImport'"  size="mini" type="info" @click="tbbjDownload">下载成本表</el-button>
              <el-button v-else-if="this.templateType === 'tbbjjpImport'"  size="mini" type="info" @click="tbbjjpDownload">下载最新投标报价表</el-button>
              <el-button v-else-if="this.templateType === 'ygdaImport'"  size="mini" type="info" @click="ygdaDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'sygzImport'"  size="mini" type="info" @click="sygzDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'wbxlwhImport'"  size="mini" type="info" @click="wbxlwhDownload">下载模板</el-button>
              <el-button v-else-if="this.templateType === 'hbsfImport'"  size="mini" type="info" @click="hbsfDownload">下载模板</el-button>
<!--              <el-button size="mini" type="info" @click="cityCodeDownload">下载城市编码</el-button>-->
              <div v-if="this.templateType === 'cbImport' || this.templateType === 'khyjImport' || this.templateType === 'fypzImport' || this.templateType === 'xjdImport' || this.templateType === 'xmbxjImport' || this.templateType === 'xjdbjImport' || this.templateType === 'xmbxjbjImport' || this.templateType === 'ndysImport' || this.templateType === 'ydysmxImport' || this.templateType === 'pldrcxImport' || this.templateType === 'amountImport' || this.templateType === 'zzImport' || this.templateType === 'bmxmsyImport' || this.templateType === 'yscbbImport' || this.templateType === 'clylImport' || this.templateType === 'khdaImport' || this.templateType === 'gdzcpdImport' || this.templateType === 'xcxyhImport' || this.templateType === 'gysmxImport' || this.templateType === 'khmxImport' || this.templateType === 'xjcbImport' || this.templateType === 'xjjpImport' || this.templateType === 'tbbjImport' || this.templateType === 'tbbjjpImport' || this.templateType === 'ygdaImport' || this.templateType === 'sygzImport' || this.templateType === 'wbxlwhImport' || this.templateType === 'hbsfImport'" slot="tip" class="el-upload__tip">只能上传xlsx文件</div>
              <div v-else slot="tip" class="el-upload__tip">只能上传csv文件</div>
          </el-upload>
      </el-card>
  </el-collapse>
</template>
<script>

import store from '@/store/index'
import { validatenull } from '@/tdcommon/validate'
// import { getImportData } from '@/api/tms/tmsquotation'

export default {
  name: 'd2-container-import-detail',
  data () {
    return {
      loading: false,
      fileList: [],
      limitUpload: 1,
      formData: [],
      importData: { type: this.importType, id: this.importId, calculateType: this.calculateType, intervalPart: JSON.stringify(this.intervalPart), ids: this.importIds, inquiryMode: this.inquiryMode, camGrade: this.camGrade, inquiryCostType: this.inquiryCostType, isTax: this.isTax, taxRate: this.taxRate, shipmentQuantity: this.shipmentQuantity, quoteState: this.quoteState, docDate: this.docDate, deptId: this.deptId, mbNo: this.mbNo },
      headers: {
        'Authorization': 'Bearer ' + store.getters.access_token,
        'TENANT-ID': 1
      },
      markone: 0,
      marktwo: 0,
      keyMark: ''
    }
  },
  props: {
    // percentage: {
    //   type: Number,
    //   default: () => 0
    // },
    importVisible: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    url: {
      type: String,
      default: () => ''
    },
    templateType: {
      type: String,
      default: () => ''
    },
    importType: {
      type: String,
      default: () => ''
    },
    importId: {
      type: String,
      default: () => ''
    },
    calculateType: {
      type: String,
      default: () => ''
    },
    intervalPart: {
      type: Array,
      default: () => []
    },
    importIds: {
      type: String,
      default: () => ''
    },
    inquiryMode: {
      type: String,
      default: () => ''
    },
    camGrade: {
      type: String,
      default: () => ''
    },
    inquiryCostType: {
      type: String,
      default: () => ''
    },
    isTax: {
      type: String,
      default: () => ''
    },
    taxRate: {
      type: String,
      default: () => ''
    },
    shipmentQuantity: {
      type: String,
      default: () => ''
    },
    quoteState: {
      type: String,
      default: () => ''
    },
    docDate: {
      type: String,
      default: () => ''
    },
    deptId: {
      type: String,
      default: () => ''
    },
    mbNo: {
      type: String,
      default: () => ''
    }
  },
  methods: {
    handleChange (file, fileList) {
      this.fileList = fileList
    },
    // 关闭时清空srclist
    clearSrcList () {
      this.$refs.upload.clearFiles() // 清空上传文件列表
    },
    // 下载城市编码
    cityCodeDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%9F%8E%E5%B8%82%E7%BC%96%E7%A0%81.xls'
    },
    // 下载有报价测算成本模板
    bjDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%9C%89%E6%8A%A5%E4%BB%B7%E6%B5%8B%E7%AE%97%E6%88%90%E6%9C%AC.csv'
    },
    // 下载有收支测算毛利模板
    szDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%9C%89%E6%94%B6%E6%94%AF%E6%B5%8B%E7%AE%97%E6%AF%9B%E5%88%A9.csv'
    },
    xjDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%AF%A2%E4%BB%B7%E5%8D%95%E6%98%8E%E7%BB%86.csv'
    },
    srmbDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%AE%BE%E5%AE%9A%E7%9B%AE%E6%A0%87%E6%94%B6%E5%85%A5%E8%A1%A8.csv'
    },
    cbglDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%88%90%E6%9C%AC%E7%AE%A1%E7%90%86%E6%98%8E%E7%BB%86.csv'
    },
    gzdxDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%89%B9%E9%87%8F%E7%9F%AD%E4%BF%A1%E6%A8%A1%E6%9D%BF.csv'
    },
    pldhcxDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%89%B9%E9%87%8F%E5%85%B1%E4%BA%AB%E5%AF%BC%E5%85%A5.csv'
    },
    ncmbDownload () {
      // window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AF%BC%E5%85%A5%E5%B9%B4%E5%88%9D%E7%9B%AE%E6%A0%87%E6%98%8E%E7%BB%86.csv'
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AF%BC%E5%85%A5%E5%B9%B4%E5%88%9D%E7%9B%AE%E6%A0%87%E6%A8%A1%E6%9D%BF%281%29.csv'
    },
    drgzDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AF%BC%E5%85%A5%E8%BF%90%E5%8D%95%E8%B7%9F%E8%B8%AA%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.csv'
    },
    // 视源批量跟踪导入模板
    sygzDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%A7%86%E6%BA%90%E8%BF%90%E5%8D%95%E6%89%B9%E9%87%8F%E8%B7%9F%E8%B8%AA%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
    },
    xhqdDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AF%BC%E5%85%A5%E9%94%80%E8%B4%A7%E6%B8%85%E5%8D%95%E6%98%8E%E7%BB%86.csv'
    },
    rgcbDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E4%BA%BA%E5%B7%A5%E6%88%90%E6%9C%AC%E5%AF%BC%E5%85%A5.csv'
    },
    blackListDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AE%A2%E6%88%B7%E9%BB%91%E5%90%8D%E5%8D%95.csv'
    },
    lcgjDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E9%87%8C%E7%A8%8B%E5%B7%A5%E5%85%B7%E6%A8%A1%E6%9D%BF.csv'
    },
    fypzDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%B4%B9%E7%94%A8%E4%BF%A1%E6%81%AF%E6%A8%A1%E6%9D%BF.xlsx'
    },
    skfkplhxDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%89%B9%E9%87%8F%E6%A0%B8%E9%94%80%E5%AF%BC%E5%85%A5.csv'
    },
    xjdDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%AF%A2%E4%BB%B7%E5%8D%95%E6%98%8E%E7%BB%86%E6%A8%A1%E6%9D%BF.xlsx'
    },
    zzDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%8C%85%E5%8F%B0%E9%A1%B9%E7%9B%AE%E4%B8%AD%E8%BD%AC%E5%8D%95%E5%AF%BC%E5%85%A5%E9%87%91%E9%A2%9D.xlsx'
    },
    amountDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%8C%85%E5%8F%B0%E9%A1%B9%E7%9B%AE%E6%89%98%E8%BF%90%E5%8D%95%E5%AF%BC%E5%85%A5%E9%87%91%E9%A2%9D.xlsx'
    },
    bmxmsyDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E9%83%A8%E9%97%A8%E9%A1%B9%E7%9B%AE%E6%8D%9F%E7%9B%8A%E8%A1%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
    },
    yscbbDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E9%A2%84%E6%97%B6%E5%B7%AE%E6%8A%A5%E8%A1%A8%E5%AF%BC%E5%85%A5%E6%95%B0%E6%8D%AE%E4%BF%A1%E6%81%AF.xlsx'
    },
    clylDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E8%BD%A6%E8%BE%86%E4%BF%A1%E6%81%AF%E5%AF%BC%E5%85%A5.xlsx'
    },
    // 客户档案导入模板
    khdaDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AF%BC%E5%85%A5%E5%AE%A2%E6%88%B7%E6%A1%A3%E6%A1%88%E4%BF%A1%E6%81%AF.xlsx'
    },
    // 固定资产盘点表 导入模板
    gdzcpdDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%9B%BA%E5%AE%9A%E8%B5%84%E4%BA%A7%E7%9B%98%E7%82%B9%E8%A1%A8.xlsx'
    },
    // 小程序用户信息 导入模板
    xcxyhDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%B0%8F%E7%A8%8B%E5%BA%8F%E7%94%A8%E6%88%B7%E4%BF%A1%E6%81%AF%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
    },
    // 供应商明细表 导入模板
    gysmxDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E4%BE%9B%E5%BA%94%E5%95%86%E6%98%8E%E7%BB%86%E8%A1%A8%E6%95%B0%E6%8D%AE.xlsx'
    },
    // 客户明细 导入模板
    khmxDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%AE%A2%E6%88%B7%E6%98%8E%E7%BB%86%E8%A1%A8%E6%A8%A1%E6%9D%BF.xlsx'
    },
    // 月度预算明细价格
    ydmxjgDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%9C%88%E5%BA%A6%E4%BB%B7%E6%A0%BC%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.csv'
    },
    // 外包线路维护
    wbxlwhDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%A4%96%E5%8C%85%E7%BA%BF%E8%B7%AF%E7%BB%B4%E6%8A%A4%E6%A8%A1%E6%9D%BF%EF%BC%88%E6%96%B0%EF%BC%89.xlsx'
    },
    // 伙伴收付导入
    hbsfDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E4%BC%99%E4%BC%B4%E6%94%B6%E4%BB%98%E6%AC%BE%E5%AF%BC%E5%85%A5%E5%BC%80%E7%A5%A8%E6%A8%A1%E6%9D%BF.xlsx'
    },
    // 动态下载 询价单报价模板
    xjdbjDownload () {
      this.$emit('emitDownload')
    },
    // 动态下载 客户合同价模板
    khyjDownload () {
      this.$emit('emitDownload')
    },
    // 动态下载 报价损益报价模板
    bjsyDownload () {
      this.$emit('emitDownload')
    },
    // 年度预算
    ndysDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E5%B9%B4%E5%BA%A6%E9%A2%84%E7%AE%97%E6%A8%A1%E6%9D%BF.xlsx'
    },
    // 月度预算明细
    ydysmxDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E6%9C%88%E5%BA%A6%E9%A2%84%E7%AE%97%E6%A8%A1%E6%9D%BF.xlsx'
    },
    // 线路运价报表 批量导入查询
    pldrcxDownload () {
      window.location.href = 'https://firs.oss-cn-shanghai.aliyuncs.com/excel/%E7%BA%BF%E8%B7%AF%E8%BF%90%E4%BB%B7%E6%8A%A5%E8%A1%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
    },
    // 询价成本 下载模板
    xjcbDownload () {
      this.$emit('emitDownload', 'xj')
    },
    // 成本纠偏 下载模板
    xjjpDownload () {
      this.$emit('emitDownload', 'jp')
    },
    // 投标报价 下载模板
    tbbjDownload () {
      this.$emit('emitDialogOpen')
    },
    // 投标报价纠偏 下载模板
    tbbjjpDownload () {
      this.$emit('emitDownload')
    },
    // 动态下载 员工档案更新模板
    ygdaDownload () {
      this.$emit('emitDownload')
    },
    // 上传
    submitUpload () {
      this.loading = true
      if (!validatenull(this.fileList)) {
        this.$refs.upload.submit()
      } else {
        this.$message.error('请选择导入文件！')
        this.loading = false
      }
    },
    handleError (response) {
      this.loading = false
      return this.$alert('msg：' + response.msg, '导入失败', { confirmButtonText: '确定' })
      // return this.$confirm('导入失败！msg：' + response.msg)
    },
    handleSuccess (response, file, fileList) {
      if (response.code !== 0) {
        this.loading = false
        this.$refs.upload.clearFiles() // 清空上传文件列表
        return this.$alert(response.msg)
      } else {
        this.loading = false
        this.$refs.upload.clearFiles() // 清空上传文件列表
        this.keyMark = response.data
        this.$emit('importDetailMsg', response.data, response.msg)
      }
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    handlePreview (file, fileList) {
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    // 判断导入的格式是不是csv，如果不是则提示
    beforeAvatarUpload (file, fileList) {
      var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1)
      const extension = testmsg === 'csv'
      const extension2 = testmsg === 'xlsx'
      // const isLt2M = file.size / 1024 / 1024 < 2
      if (!extension2 && (this.templateType === 'cbImport' || this.templateType === 'khyjImport' || this.templateType === 'fypzImport' || this.templateType === 'xjdImport' || this.templateType === 'xmbxjImport' || this.templateType === 'xjdbjImport' || this.templateType === 'xmbxjbjImport' || this.templateType === 'ndysImport' || this.templateType === 'ydysmxImport' || this.templateType === 'pldrcxImport' || this.templateType === 'amountImport' || this.templateType === 'zzImport' || this.templateType === 'bmxmsyImport' || this.templateType === 'yscbbImport' || this.templateType === 'clylImport' || this.templateType === 'khdaImport' || this.templateType === 'gdzcpdImport' || this.templateType === 'xcxyhImport' || this.templateType === 'gysmxImport' || this.templateType === 'khmxImport' || this.templateType === 'xjcbImport' || this.templateType === 'xjjpImport' || this.templateType === 'tbbjImport' || this.templateType === 'tbbjjpImport' || this.templateType === 'ygdaImport' || this.templateType === 'sygzImport' || this.templateType === 'wbxlwhImport' || this.templateType === 'hbsfImport')) {
        this.loading = false
        this.$message.error('导入文件只支持 xlsx 格式！')
        return false
      } else if (!extension && this.templateType !== 'cbImport' && this.templateType !== 'khyjImport' && this.templateType !== 'fypzImport' && this.templateType !== 'xjdImport' && this.templateType !== 'xmbxjImport' && this.templateType !== 'xjdbjImport' && this.templateType !== 'xmbxjbjImport' && this.templateType !== 'ndysImport' && this.templateType !== 'ydysmxImport' && this.templateType !== 'pldrcxImport' && this.templateType !== 'amountImport' && this.templateType !== 'zzImport' && this.templateType !== 'bmxmsyImport' && this.templateType !== 'yscbbImport' && this.templateType !== 'clylImport' && this.templateType !== 'khdaImport' && this.templateType !== 'gdzcpdImport' && this.templateType !== 'xcxyhImport' && this.templateType !== 'gysmxImport' && this.templateType !== 'khmxImport' && this.templateType !== 'xjcbImport' && this.templateType !== 'xjjpImport' && this.templateType !== 'tbbjImport' && this.templateType !== 'tbbjjpImport' && this.templateType !== 'ygdaImport' && this.templateType !== 'sygzImport' && this.templateType !== 'wbxlwhImport' && this.templateType !== 'hbsfImport') {
        this.loading = false
        this.$message.error('导入文件只支持 csv 格式！')
        return false
      }
      // if (!isLt2M) {cbImport
      //   this.$message.error('上传头像图片大小不能超过 2MB!')
      // }
      // return (extension || extension2) && isLt2M
      return extension || extension2
    },
    handleSelect (val) {
      this.$emit('change', val)
    }
  },
  watch: {
    importType (newVal, oldVal) {
      if (newVal) {
        this.importData = { type: newVal }
      }
    },
    importId (newVal, oldVal) {
      if (newVal) {
        this.importData = { id: newVal }
      }
    },
    importIds (newVal, oldVal) {
      if (newVal) {
        this.importData = { ids: newVal }
      }
    },
    calculateType (newVal, oldVal) {
      if (newVal) {
        this.importData = { calculateType: newVal }
      }
    },
    intervalPart (newVal, oldVal) {
      if (newVal) {
        this.importData = { intervalPart: JSON.stringify(newVal) }
      }
    },
    inquiryMode (newVal, oldVal) {
      if (newVal) {
        this.importData = { inquiryMode: newVal }
      }
    },
    camGrade (newVal, oldVal) {
      if (newVal) {
        this.importData = { camGrade: newVal }
      }
    },
    inquiryCostType (newVal, oldVal) {
      if (newVal) {
        this.importData = { inquiryCostType: newVal }
      }
    },
    isTax (newVal, oldVal) {
      if (newVal) {
        this.importData = { isTax: newVal }
      }
    },
    taxRate (newVal, oldVal) {
      if (newVal) {
        this.importData = { taxRate: newVal }
      }
    },
    shipmentQuantity (newVal, oldVal) {
      if (newVal) {
        this.importData = { shipmentQuantity: newVal }
      }
    },
    quoteState (newVal, oldVal) {
      if (newVal) {
        this.importData = { quoteState: newVal }
      }
    },
    docDate (newVal, oldVal) {
      if (newVal) {
        this.importData = { docDate: newVal }
      }
    },
    deptId (newVal, oldVal) {
      if (newVal) {
        this.importData = { deptId: newVal }
      }
    },
    mbNo (newVal, oldVal) {
      if (newVal) {
        this.importData = { mbNo: newVal }
      }
    }
  },
  mounted () {
  }
}
</script>

<style lang="scss">
</style>

<vue-filename-injector>
export default function (Component) {
  Component.options.__source = "src/components/d2-container-import-detail/index.vue"
}
</vue-filename-injector>
